



















import { Component, Vue } from 'vue-property-decorator'
import { GameModule } from '@/store/modules'

@Component({
  components: {
    CharacterStatCard: () => import('@/components/Character/CharacterStatCard.vue')
  }
})
export default class ProfileAbout extends Vue {
  @GameModule.State characters
}
